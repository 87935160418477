@import '../../../../../../styles/variables.scss';

.message {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.4s linear, opacity 0.4s ease;
  line-height: 1.4;
}

.indicator:hover
.message {
  visibility: visible;
  opacity: 1;
}


/* The visuals */

.indicator {
  position: relative;
}

.indicator {
	font-family: "Ubuntu-Regular";
  cursor: pointer;
  appearance: none;
  width: calc(25% - 10px);
  height: 10px;;
  background-color: $success;
  border-radius: 5px;
  position: relative;
  font-size: 16px;
  margin-right: 10px;
  &.not-active {
    background-color: #FAA250;
  }
}

.message {
  @include transition;
  font-size: 14px;
  color: $dark;
  z-index: 300;
  text-align: center;
  background-color: $white;
  border: 2px solid $success;
  width: 140px;
  padding: 5px 10px;
  border-radius: 5px;
  position: absolute;
  transform: translate(-6px , 15px);
  box-shadow: $card-shadow;
  p {
    margin: 0px;
  }
  .value {
    color : $success;
  }
  &.not-active {
    border: 2px solid #FAA250;
    .value {
      color : #FAA250;
    }
  }
}

.message {
  &::after {
  content: '';
  position: absolute;
  border: 10px solid;
  border-color: $success transparent transparent transparent;
  top: -20px;
  left: 5px;
  transform: translateX(0%) rotate(180deg);
  }
  &.not-active {
    &::after {
      border-color: #FAA250 transparent transparent transparent;
    }
  }
}
