@import '../../../../../../styles/variables.scss';

.card {
  position: relative;
  background-color: rgba($dark ,0.15);
  min-height: 140px;
  width: calc(50% - 10px);
  border-radius: $radius;
  padding: 20px;
  .btn {
    margin-top: 20px;
    background-color: $primary;
    color: $white;
    width: 100%;
    border-radius: $radius;
    font-weight: bold;
  }
}