@import '../../../../../styles/variables.scss';

.container {
  width: 100%;
  font-size: 14px;
  .centers {
    padding-bottom: 20px;
    .row {
      display: flex;
      width: 100%;
      margin: 15px 0px;
      .col {
        width: calc(100% / 2);
        display: flex;
        align-items: center;
        &:not(:first-child) {
          padding-left: 10px;
        }
        &.actions {
          justify-content: flex-end;
          .delete {
            display: flex;
            align-items: center;
            color: rgba($dark, 0.3);
            &:hover {
              color: $error;
            }
            svg {
              transform: translate(5px , -1px)
            }
          }
        }
        &.label {
          margin-bottom: 10px;
          &:not(:first-child) {
            border-left: 1px solid #DDDDDD;
          }
        }
      }
    }
  }
}
