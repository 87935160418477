@import '../../../../../styles/variables.scss';

.container {
  width: 100%;
  .actions {
    display: flex;
    .changes {
      display: flex;
      align-items: center;
      p {
        opacity: 0;
        margin: 0px 5px;
      }
      svg {
        color: $primary;
        font-size: 24px;
        margin-right: 6px;
      }
      &:hover {
        p {
          opacity: 0.5;
        }
      }
    }
  }
}

.lists {
  padding: 20px 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  button {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    border: 1px solid rgba($dark, 0.2);
    border-radius: $radius;
    font-weight: bold;
    text-align: left;
    p {
      margin: 0px;
    }
    svg {
      color: $primary;
      font-size: 24px;
      margin-right: 6px;
    }
    &:hover {
      background-color: rgba($primary,0.1);
    }
  }
}