@import '../../../../../styles/variables.scss';

.view {
  padding: 0px 30px 20px 30px;
  overflow: auto;
  height: 100%;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $primary;
  }
  .edit {
    width: 100%;
    position: absolute;
    right: 40px;
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
    padding-right: 15px
  }
  .doctor-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0px;
  }
}
