@import '../../../../../../styles/variables.scss';

.container {
  min-width: 500px;
  width: 60vw;
  min-height: 400px;
  &.preview {
    width: 100%;
    min-width: 100%;
  }
  .header {
    display: flex;
    align-items: center;
    .add {
      background-color: $primary;
      border-radius: $radius;
      position: relative;
      .new {
        color: $white;
        padding: 6px 12px;
        width: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        svg {
          font-size: 18px;
          transform: translateY(1px);
        }
      }
      .select {
        position: absolute;
        top: 28px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: $primary;
        border-bottom-left-radius: $radius;
        border-bottom-right-radius: $radius;
        .new {
          color: rgba($white,0.6);
        }
      } 
    }
    .search {
      margin-left: 10px;
      position: relative;
      input {
        border: none;
        padding: 8px 10px;
        width: 200px;
        border-bottom: 1px solid rgba($dark, 0.3);
        &:focus {
          outline: none;
          border-bottom: 1px solid $primary;
        }
      }
      svg {
        position: absolute;
        right: 0px;
        top: 8px;
        color: rgba($dark, 0.6);
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    margin: 20px 0px;
    .back {
      font-size: 16px;
      font-weight: bold;
    }
    .edit {
      margin: 0px;
      font-size: 18px;
      padding: 0px;
      display: none;
      align-items: center;
      justify-content: center;
      color: $primary;
    }
    &:hover {
      .edit {
        display: flex;
      }
    }
  }
  .list {
    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px;
      width: 100%;
      border-bottom: 1px solid rgba($dark, 0.2);
      font-weight: bold;
      font-size: 14px;
      color: $dark;
      cursor: pointer;
      position: relative;
      svg {
        color: $primary;
        font-size: 24px;
        margin-right: 6px;
      }
      .name {
        display: flex;
        align-items: center;
        padding: 10px;
        min-width: 90%;
      }
      .options {
        padding: 0px 10px;
        position: relative;
        .select {
          position: absolute;
          right: 18px;
          top: -4px;
          background-color: $white;
          width: 120px;
          padding: 5px;
          border: 1px solid #CDCDCD;
          box-shadow: -10px 0px 28px rgba(95, 101, 124, 0.098667);
          border-radius: 3px;
          display: none;
          button {
            font-size: 12px;
            padding: 5px 8px;
            width: 100%;
            text-align: left;
            border-radius: 3px;
            &:hover {
              background-color: rgba($dark, 0.1);
            }
          }
        }
        &:hover {
          .select {
            display: block;
          } 
        }
      }  
      .move {
        position: absolute;
        left: 0px;
        top: 38px;
        background-color: $white;
        width: 250px;
        border: 1px solid #CDCDCD;
        box-shadow: -10px 0px 28px rgba(95, 101, 124, 0.098667);
        border-radius: 3px;
        z-index: 1;
        .label {
          padding: 10px 20px;
        }
        .folders {
          max-height: 150px;
          overflow-y: auto;
          padding: 0px 10px;
          @include scroolBar;
          button {
            font-size: 14px;
            padding: 5px 10px;
            width: 100%;
            text-align: left;
            border-radius: 3px;
            display: flex;
            align-items: center;
            &:hover {
              background-color: rgba($dark, 0.1);
            }
          }
        }
      }
      &.file {
        svg {
          color: rgba($primary,0.6);
          font-size: 24px;
        }
        .delete {
          display: none;
          margin: 0px 10px;
          padding: 0px;
          svg {
            font-size: 20px;
          }
        }
      }
      &:hover {
        background-color: rgba($primary,0.1);
        .delete {
          display: block;
        }
      }
    }
  }
}