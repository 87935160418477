@import '../../../../styles/variables.scss';

.container {
  @include animation-fade(slideIn);
	animation-name: slideIn;
  animation-duration: 0.5s;
}

.list {
  width: 100%;
  margin-top: -20px;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 40px;
  overflow-y: auto;
  height: calc(100vh - 235px);
  .row {
    transform: translateX(5px);  
  }
  &::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $primary;
  }
}

.row {
  @include transition;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .btn-active {
    @include transition;
    opacity: 0;
    display: flex;
    justify-content: flex-end;
    button {
      color: rgba($dark, 0.4);
      font-size: 16px;
      margin-right: 20px;
      border-radius: $radius;
      &:hover {
        color: rgba($dark, 0.8);
      }
    }
  }
  &:hover {
    .btn-active {
      opacity: 1;
    }
  }
  &.sort {
    transform: translateY(-10px);
    display: flex;
    margin: auto;
    margin-top: 20px;
    position: relative;
    width: 90%;
    @media only screen and (min-width: $md-screen) {
      width: 96%;
    }
    @media only screen and (min-width: $lg-screen) {
      width: 90%;
    }
  }
  &.is-active {
    border: 1px solid $primary;
    box-shadow: 0px 10px 28px rgba($primary , 0.2);
    .name {
      color: $primary;
    }
    color: $primary;
  }
  .col {
    padding-left: 20px;
    width: 30%;
    &.name {
      width: 30%;
      @include transition;
    }
  }
}