@import '../../../styles/variables.scss';

.modale-container {
  @include transition;
  position: fixed;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &.is-active {
    background-color: rgba($primary , 0.3);
    z-index: 1001;
    pointer-events: all;
  }
  &.preview {
    width: 100%;
    height: 100vh;
    .modale {
      width: 90%;
    }
  }
  .modale {
    @include transition;
    pointer-events: all;
    padding: 30px;
    background-color: $white;
    transform: scale(0);
    border-radius: $radius;
    max-height: 90%;
    min-height: 100px;
    overflow-y: auto;
    &.is-active {
      transform: scale(1);
    }
    .close {
      position: absolute;
      right: 0px;
      top: 0px;
      width: 32px;
      height: 32px;
      margin: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      img {
        width: 25px;
        height: 25px;;
      }
      &:hover {
        transform: scale(1.2);
      }
    }
  } 
}