@import '../../../../../styles/variables.scss';

.container {
  width: 100%;
  font-size: 14px;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #DDDDDD;
    margin-bottom: 20px;
    h1 {
      font-weight: bold;
      text-transform: initial;
      font-size: 24px;
    }
  }
  .infos {
    display: flex;
    margin: 10px 0px;
    .info {
      display: flex;
      align-items: center;
      border-right: 1px solid #DDDDDD;
      margin-right: 20px;
      p {
        margin: 8px 0px;
        padding-right: 20px;
      }
    }
    &.preview {
      border-bottom: 1px solid #DDDDDD;
      padding-bottom: 20px;
      .col {
        width: 50%;
        margin-left: 20px;
        &:first-child {
          border-right: 1px solid #DDDDDD;
          margin-left: 0px;
        }
        .info {
          margin: 10px 0px;
          border: none;
        }
      }
    }
  }
  .description {
    display: flex;
    .label {
      margin-right: 20px;
      min-width: 100px;
    }
  }
}