@import './variables.scss';

body, html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
  color: $dark;
  font-family: "Ubuntu-Regular" , -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bg-color;
}

* {
  box-sizing: border-box;
}

//MAIN-CONTAINERS

.app-container {
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 0;
  overflow: hidden;
}

.page-container {
  top: 80px;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: -1;
  height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  opacity: 1;
  @media only screen and (min-width: $md-screen) {
    top: 80px;
    height: calc(100vh - 80px);
  }
}

.content {
  position: relative;
  width: 90%;
  @media only screen and (min-width: $md-screen) {
    width: 96%;
  }
  @media only screen and (min-width: $lg-screen) {
    width: 90%;
  }
}

//MAIN_CLASSES

.uppercase {
  text-transform: uppercase;
}

.semi-bold {
  font-family: "AnyBody-SemiBold";
}

.bold {
  font-weight: bold;
}

.regular {
  font-family: "Anybody-Regular";
}

label {
  font-family: "Ubuntu-Regular";
}

button {
  @include btn;
}

.m-md {
  margin: $margin-md
} 

.text-center {
  text-align: center;
}

.text-error {
  font-size: $small;
  margin: 6px;
  min-height: 16px;
  color: $error;
}

.text-success {
  color: $success;
}

.grey-label {
  font-family: "AnyBody-SemiBold";
  text-transform: uppercase;
  color: rgba($dark , 0.25 );
}

h1 {
  text-transform: uppercase;
  font-size: 21px;
  font-family: "Anybody-Bold";
}

.btn {
  border: none;
  cursor: pointer;
  padding: 10px 30px;
  border-radius: $radius;
  font-size: 14px;
  text-transform: uppercase;
  &.primary {
    color: $white;
    text-decoration: none;
    background-color: $primary;
    &:hover {
      transform: scale(1.03)
    }
  }
}

input {
  font-family: "Ubuntu-Regular";
  &.primary {
    border: none ;
    height: 50px;
    border-radius: 3px;
    padding-left: $margin-md;
    color: $dark;
    background-color: $white;
    box-shadow: 0px 10px 28px 0px rgba(156,167,177,0.30);
    font-size: 16px;
    margin-top: 4px;
    transition: all 0.2s ease-in-out;
    &:focus {
      outline: none;
    }
  }
}

.modale-content {
  width: 100%;
  h2 {
    margin-top: 0px;
    font-size: 18px;
    text-align: center;
    font-family: "Anybody-Bold";
    text-transform: uppercase;
  }
  .row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .col  {
      width: 50%;
    }
  }
  .modale-actions{
    margin-top: 20px;
    width: 100%;
    &.center {
      display: flex;
      justify-content: center;
      button {
        margin: 0px 10px;
      }
    }
    &.start {
      display: flex;
      justify-content: flex-start;
      button {
        margin-right: 10px;
      }
    }
    &.between {
      display: flex;
      justify-content: space-between;
    }
    .btn-delete {
      display: flex;
      align-items: center;
      font-family: "Ubuntu-Regular";
      padding: 10px 10px;
      border-radius: 3px;
      color: red;
      p {
        margin: 0px 5px;
      }
      &:hover {
        background-color: rgba( red , 0.2);

      }
    }
  }
}

.row-box {
	@include transition;
	background-color: $white;
	box-shadow: 0px 10px 28px rgb(224, 224, 224);
  border-radius: $radius;
  min-height: 55px;
  cursor: pointer;
  &:not(:first-child) {
    margin-top: 20px;
  };
  &:hover {
    box-shadow: 0px 10px 18px rgb(197, 197, 197);
    transform: scale(1.2);
  }
}

.text-grey {
  color: rgba($dark , 0.5 );
}

.edit-study-section {
  margin-bottom: 5px;
  padding-bottom: 5px;
  width: 100%;
  border-bottom: 1px solid #DDDDDD;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0px;
      h3 {
        font-family: "Ubuntu-Regular";
        font-size: 16px;
      }
      .panel-toggle {
        transform: translateY(2px);
      }
    } 
  }
  .panel {
    @include transition;
    overflow: hidden;
  }
  .grey-box {
    display: flex;
    border-radius: 3px;
    background-color: #EBECED;
    margin: 0px 10px 10px 0px;
    font-family: "Ubuntu-Regular";
  }
  .btn-edit-row {
    display: flex;
    font-family: "Ubuntu-Regular";
    text-align: left;
    border-radius: 3px;
    background-color: #EBECED;
    padding: 6px 10px;
    width: 90%;
    font-size: 14px;
    text-decoration: none;
    color: $dark;
    &:hover {
      background-color: rgba($primary , 0.3);
    }
  }
}

.medocta-link {
  background-color: #FFA56D;
  color: $white;
  position: absolute;
  right: 20px;
  bottom: 20px;
  padding: 10px;
  border-radius: 22px;
  @include transition;
  &:hover {
    transform: scale(1.2);
  }
}

.puce {
  width: 10px;
  min-width: 10px;
  height: 10px;
  border-radius: 10px;
  transform: translateX(-6px)
}


