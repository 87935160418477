@import '../../../../../styles/variables.scss';

.container {
  width: 100%;
  font-size: 14px;
  border-bottom: none;
  .meets {
    padding-bottom: 20px;
    h4 {
      margin-bottom: 5px;
    }
    .text {
      padding-bottom: 20px;
      padding-left: 32px;
    }
    .contributors {
      display: flex;
      flex-wrap: wrap;
      p {
        margin: 5px 10px 0px 0px;
        color: rgba($dark , 0.5 );
        border: 1px solid rgba($dark , 0.5 );
        border-radius: 3px;
        padding: 5px 10px;
      }
    }
    .row {
      display: flex;
      width: 100%;
      margin: 10px 0px;
      .col {
        width: calc(100% / 2);
        display: flex;
        align-items: center;
        &:not(:first-child) {
          padding-left: 10px;
        }
        &.actions {
          justify-content: flex-end;
          .delete {
            svg {
              fill: rgba($dark, 0.3);
              &:hover {
                fill: $error;
              }
            }
          }
        }
        &.label {
          margin-bottom: 10px;
          &:nth-child(2n) {
            border-left: 1px solid #DDDDDD;
          }
        }
        &.submit {
          display: flex;
          justify-content: flex-end;
          button {
            max-width: 180px;
            display: flex;
            justify-content: center;
            background-color: #EBECED;
            &:hover {
              background-color: $success;
              color: $white;
            }
          }
        }  
        .icon {
          display: flex;
          justify-content: center;
        }
        .isActive {
          background-color: rgba($primary, 1);
          color: $white;
          &:hover {
            background-color: rgba($primary, 0.5);
          }
        }
      }
    }
  }
}