@import '../../../styles/variables.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  header {
    width: 100%;
    padding: 50px 5% 0px 5%; 
    display: flex;
    z-index: 300;
    justify-content: space-between;
    button {
      color: rgba($dark, 0.8);
      min-width: 140px;
      box-shadow: $shadow;
      background-color: $white;
      border-radius: $radius;
      padding: 10px;
      margin-right: 20px;
      &.create {
        font-family: "Anybody-SemiBold";
        text-transform: uppercase;
        margin-right: 0px;
        padding: 15px;
      }
      &:hover {
        background-color: $primary;
        color: $white;
      }
      &.is-active {
        background-color: $dark;
        color: $white;
      }
    }
  }
 .map {
  position: absolute;
  width: 100%;
  height: 100%;
  @include transition;
  @include animation-fade(slide);
	animation-name: slide;
  animation-duration: 0.5s;
  &.panel-open {
    transform: translateX(-25%);
  }
 }
 .legend {
    position: relative;
    max-width: 150px;
    left: 5%;
    opacity: 1;
    h4 {
      margin: 5px 0px;
    }
    @include transition;
    .phase {
      width: 100%;
      display: flex;
      align-items: center;
      .puce {
        min-width: 12px;
        width: 12px;
        height: 12px;
        border-radius: 10px;
        margin-right: 5px;
      }
      p {
        font-size: 14px;
        margin: 8px;
      }
    }
 }
 .select-map {
    height: 100%;
    position: absolute;
    right: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .btn-container {
      background-color: $bg-color;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px 5px;
      border-radius: 5px;
      margin-right: 20px;
      button {
        img {
          max-width: 82px;
          &.France {
            transform: translateX(-10px)
          }
        }
        font-family: "Ubuntu-Regular";
        p {
          margin-top: 5px;
        }
        &.not-active {
          opacity: 0.3;
        }
        &:hover {
          opacity: 0.4;
        }
      }
    }
  }
}