@import '../../../../../../styles/variables.scss';

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  form {
    width: 450px;
    .file {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 200px;
      margin: 5px 0px;
      text-transform: none;
      display: flex;
      align-items: center;
      padding: 10px 20px;
      font-size: 14px;
      border: 1px solid $primary;
      color: $primary;
      border-radius: 3px;
      .link {
        display: flex;
        align-items: center;
        p {
          margin: 0px;
          text-decoration: underline;
          color: $primary;
        }
        svg {
          margin-right: 10px;
          fill: $primary;
        }
      }
      .delete {
        display: flex;
        align-items: center;
        font-family: "Ubuntu-Regular";
        p {
          color: red;
          margin: 0px;
        }
        svg {
          margin-right: 5px ;
          fill: red;
        }
      }
    }
  }
}