@import '../../../../styles/variables.scss';

.container {
  width: 100%;
  .timeline {
    min-height: 100%;
    overflow-y: auto;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      height: 10px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: $primary;
    }
    .grid {
      display: flex;
      justify-content: flex-start;
      padding: 0px;
      pointer-events: none;
      z-index: 0;
      li {
        list-style: none;
        font-size: 14px;
        border-left: 1px solid rgba(#606060 , 0.2);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        color:  #606060;
        p {
          margin: 0px 5px;
        }
      }
    }
  }
}