@import '../../../../../../styles/variables.scss';

.container {
  min-width: 500px;
  width: 60vw;
  min-height: 400px;
  &.preview {
    width: 100%;
    min-width: 100%;
  }
  h2 {
    margin: 0px;
  }
  .changes {
    padding-top: 15px;
    overflow-y: auto;
    max-height: 400px;
    @include scroolBar;
    .change {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      svg {
        color: $primary;
      }
      p {
        margin: 0px 8px ;
      }
    }
  }
}