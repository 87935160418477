@import '../../../styles/variables.scss';

.panel {
  @include transition;
  position: fixed;
  top: 20px;
  right: 20px;
  width: calc(50% - 20px);
  height: calc(100vh - 40px);
  background-color: $white;
  box-shadow: 0px 10px 28px rgb(197, 197, 197);
  transform: translateX(110%);
  overflow:hidden;
  opacity: 0;
  z-index: 1001;
  &.is-open {
    transform: translateX(0px);
    opacity: 1;
  }
  .close {
    position: absolute;
    right: 0px;
    top: 0px;
    margin: 8px 5px;
    &:hover {
      transform: scale(1.2);
    }
  }
}