@import '../../../../../../styles/variables.scss';

.container {
  width: 100%;
  .list {
    width: 100%;
    padding-bottom: 10px;
    .row {
      display: flex;
      width: 100%;
      margin: 8px 0px;
      .col {
        width: 40%;
        display: flex;
        align-items: center;
        font-size: 14px;
        &.date {
          padding-left: 10px;
        }
      }
    }
  }
}