@import '../../../../../styles/variables.scss';

.container {
  width: 100%;
  font-size: 14px;
  .chart {
    width: 100%;
    height: 320px;
    padding-top: 20px;
    overflow: visible;
  }
  .select {
    width: 200px;
  }
}