@import '../../../../../styles/variables.scss';

.container {
  width: 100%;
  .patients {
    padding: 10px 0px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    .patient {
      display: flex;
      align-items: center;
      padding-right: 2px;
      &.btn {
        &:hover {
          background-color: rgba($primary , 0.3);
        }
      }
      p {
        margin: 6px;
      }
      button {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      svg {
        fill: rgba($dark, 0.3);
        &:hover {
          fill: $error;
        }
      }
    }
  }
}