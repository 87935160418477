@import '../../../styles/variables.scss';

.container {
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  overflow-y: auto;
  scroll-behavior: smooth;
  padding-top: 80px;
  &::-webkit-scrollbar {
    width: 10px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $primary;
  }
  header {
    margin: 20px 10px;
    top: 75px;
    position: fixed;
    a {
      font-family: "Anybody-SemiBold";
      text-transform: uppercase;
      text-decoration: none;
      display: flex;
      align-items: center;
      color: $primary;
      svg {
        margin-right: 4px;
      }
    }
  }
  aside {
    top: 130px;
    position: fixed;
    width: calc(25% - 20px);
    ul {
      padding-left: 38px;
      font-family: "Anybody-SemiBold";
      text-transform: uppercase;
      li {
        list-style: none;
        margin: 30px 0px;
        cursor: pointer;
        color: rgba($dark, 0.25);
        @include transition;
        &:hover {
          color: rgba($dark, 0.8);
        }
        &.is-active {
          color: $primary;
        }
        .puce {
          @include transition;
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          opacity: 0;
          transform: translate(-55px , 5px);
          background-color: $primary;
          &.is-active {
            transform: translate(-20px , 5px);
            opacity: 1;
          }
        }
        a {
          text-decoration: none;
        }
      }
    }

  }
  main {
    float: right;
    width: calc(75% - 20px);
    height: 100%;
    .main-content {
      border-radius: 3px;
      padding: 0px 30px;
      background: #FFFFFF;
      box-shadow: -10px 0px 28px rgba(95, 101, 124, 0.098667);
    }
    .delete-container {
      padding-bottom: 30px
    }
  }
}

.modale {
  width: 500px;
}

.btn-delete {
  display: flex;
  align-items: center;
  font-family: "Ubuntu-Regular";
  padding: 10px 10px;
  margin: 20px 0px;
  border-radius: 3px;
  color: red;
  p {
    margin: 0px 5px;
  }
  &:hover {
    background-color: rgba( red , 0.2);
  }
}