@import '../../../styles/variables.scss';

.studies {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .btn-create {
    @include transition;
    width: 20%;
    color: $primary;
    font-family: "Anybody-Bold";
    font-size: 18px;
    border: 2px dashed $primary;
    padding: 10px;
    margin-left:10px;
    &:hover {
      border: 2px solid $primary;
      background-color: rgba($primary , 0.1)
    }
  }
  .filters {
    margin-top: 34px;
    position: absolute;
    transform: translateX(-5px);  
  }
  .sort {
    transform: translateY(-10px); 
  }
  .list {
    width: 100%;
    margin-top: 130px;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 40px;
    height: calc(100vh - 198px);
    @media only screen and (min-width: 1320px) {
      margin-top: 110px;
    }
    &.is-doctor {
      margin-top: 72px;
      height: calc(100vh - 155px);
    }
    overflow-y: auto;
    .row {
      transform: translateX(5px);  
    }
    &::-webkit-scrollbar {
      width: 10px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $primary;
    }
  }
  .row {
    @include transition;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &.is-active {
      border: 1px solid $primary;
      box-shadow: 0px 10px 28px rgba($primary , 0.2);
      .name {
        color: $primary;
      }
      color: $primary;
    }
    .col {
      padding-left: 20px;
      width: calc(35% / 2);
      text-align: left;
      &.name {
        width: 20%;
        @include transition;
      }
      &.date {
        width: 18%;
      }
      &.status {
        width: 25%;
        display: flex;
        align-items: center;
        .puce {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          border-radius: 10px;
        }
      }
    }
  }
  .close-panel-button {
    padding-top: 13px;
  }
}
