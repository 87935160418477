@import '../../../styles/variables.scss';

.container {
  padding: 20px 0px 0px 20px;
  .row {
    display: flex;
    overflow: auto;
    width: 100vw;
    height: calc(100%);
    &::-webkit-scrollbar {
      height: 10px;
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(194, 194, 194);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: $primary;
    }
    .col {
      min-width: 350px;
      h1 {
        margin-top: 0px;
      }
    }
  }
}