@import '../../../../styles/variables.scss';

.input {
  width: 100%;
  input {
    @include transition;
    width: 100%;
    font-family: "Ubuntu-Regular";
    height: 45px;
    padding: 16px;
    font-size: 14px;
    margin-top: 5px;
    border: 0px solid $light-grey;
    transition: all 0.2s ease-in-out;
    background: $white;
    box-shadow: 0px 10px 28px rgb(224, 224, 224);
    border-radius: 3px;
    &:focus {
      outline: none;
      border: 1px solid $primary;
      color: $primary;
    }
  }
  .error-message {
    margin: 0px;
    color: $error;
    min-height: 20px;
    padding-top: 3px;
    font-size: 12px;
  }
}