@import '../../../../../../styles/variables.scss';

.container {
  width: 550px;
  min-height: 250px;
  .col-2 {
    width: calc(50% - 10px);
  }
  .check {
    padding: 20px 0px;
  }
}