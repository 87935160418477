@import '../../../../../../styles/variables.scss';

.container {
  width: 500px;
  .col-2 {
    width: calc(50% - 10px);
  }
  .list {
    padding: 20px 0px;
    .inclusion {
      border-bottom: 1px solid $primary;
      padding: 10px 0px;
      input {
        padding: 5px 10px;
      }
      p {
        margin: 8px;
        width: 40%;
      }
    }
  }
}