@import '../../../../../styles/variables.scss';

.container {
  width: 100%;
  font-size: 14px;
  .centers {
    padding-bottom: 20px;
    .row {
      display: flex;
      width: 100%;
      margin: 10px 0px;
      .col {
        width: calc(90% / 4);
        display: flex;
        align-items: center;
        &:not(:first-child) {
          padding-left: 10px;
        }
        &.actions {
          justify-content: flex-end;
          .delete {
            svg {
              fill: rgba($dark, 0.3);
              &:hover {
                fill: $error;
              }
            }
          }
        }
        &.patients {
          width: 10%;
        }
        &.label {
          margin-bottom: 10px;
          &:not(:first-child) {
            border-left: 1px solid #DDDDDD;
          }
        }
      }
    }
  }
}
