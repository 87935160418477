@import '../../../styles/variables.scss';

.tooltip {
  @include transition;
  width: 160px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  background-color: $white;
  position: fixed;
  z-index: 300;
  pointer-events: none;
  border-radius: $radius;
  p {
    text-align: center;
    width: 100%;
    margin: 5px;
    font-size: 14px;
    .code {
      font-family: "Anybody-Bold";
      text-transform: uppercase;
    }
  }
}