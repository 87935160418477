@import '../../../../../../styles/variables.scss';

.container {
  width: 500px;
  .col-2 {
    width: calc(50% - 10px);
  }
  .info {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      text-align: center;
    }
  }
}