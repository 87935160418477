@import '../../../../../styles/variables.scss';

.container {
  width: 100%;
  font-size: 14px;
  .centers {
    padding-bottom: 20px;
    .row {
      display: flex;
      width: 100%;
      margin: 10px 0px;
      .col {
        width: calc(50% / 2);
        display: flex;
        align-items: center;
        word-break: break-all;
        &.name {
          width: 45%;
          .service {
            opacity: 0.4;
            display: inline-block;
          }
        }
        &.inclusions {
          width: 15%;
        }
        &:not(:first-child) {
          padding-left: 12px;
        }
        .patients {
          width: calc(90% - 20px);
        }
        p {
          margin-right: 5px
        }
        .border {
          border-left: 5px solid $primary;
          padding: 8px ;
        }
        .border-service {
          border-left: 5px solid rgba($primary , 0.5);
          padding: 8px ;
          &.center {
            padding: 8px ;
            .service {
              margin-left: 5px;
            }
          }
        }
        &.actions {
          justify-content: flex-end;
          .delete {
            svg {
              fill: rgba($dark, 0.3);
              &:hover {
                fill: $error;
              }
            }
          }
        }
        &.label {
          margin-bottom: 10px;
          &:not(:first-child) {
            border-left: 1px solid #DDDDDD;
            padding-left: 10px;
          }
        }
      }
    }
  }
}