@import '../../../../styles/variables.scss';

.container {
  .list {
    padding-top: 8px;
    width: 100%;
    .btn {
      @include transition;
      border: 1px solid $primary;
      cursor: pointer;
      color: $primary;
      padding: 5px 10px;
      border-radius: 20px;
      margin-right: 10px;
      margin-bottom: 10px;
      font-size: 14px;
      font-family: "Ubuntu-Regular";
      &:hover {
        background-color: rgba($primary , 0.2)
      }
      &.is-active {
        background-color: $primary;
        color: $white;
      }
    }
  }
  .error-message {
    margin: 0px;
    color: $error;
    min-height: 20px;
    padding-top: 3px;
    font-size: 12px;
  }
}