@import '../../../styles/variables.scss';

.container {
  overflow-y: auto;
  .content {
    position: relative;
    width: 90%;
    @media only screen and (min-width: $md-screen) {
      width: 80%;
    }
    @media only screen and (min-width: $lg-screen) {
      width: 70%;
    }
    h1 {
      font-size: 40px;
      margin-bottom: 0px;
      &:after {
        content:'';
        display: inline-block;
        border-bottom:  11px solid rgba($primary , 0.5);
        width: 35%;
        position: absolute;
        left: -12px;
        transform: translateY(34px);
      }
    }
    .intro {
      text-transform: uppercase;
      font-family: "AnyBody-SemiBold";
    }
    h3 {
      font-weight: bold;
      margin-top:40px;
    }
    li {
      margin: 10px 0px;
    }
    table {
      margin: 40px 0px;
      border-collapse: collapse;
      th {
        background-color: $primary;
        padding: 15px 20px;
        text-align: left;
        color: $white;
      }
      td {
        background-color: rgba($primary , 0.2);
        padding: 10px 20px;
        text-align: left;
        border-bottom: 1px solid $white;
      }
    }
  }
  a {
    color: $primary;
  }
  .end {
    width: 100%;
    height: 50px;
  }
}