@import '../../../../../../styles/variables.scss';

.container {
  width: 500px;
  min-height: 250px;
  .row {
    display: flex;
    justify-content: space-between;
    .col-2 {
      width: calc(50% - 10px);
    }
    .info {
      display:flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 14px;
      p {
        text-align: left;
      }
      .btn-delete {
        display: flex;
        align-items: center;
        font-family: "Ubuntu-Regular";
        padding: 10px 0px;
        border-radius: 3px;
        color: $primary;
        p {
          margin: 0px 5px;
        }
        &:hover {
          color:red ;
        }
      }
    }
  }
}