@import '../../../../styles/variables.scss';

.container {
  width: auto;
  margin: 40px;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $primary;
    padding-bottom: 20px;
    margin-bottom: 30px;
    h2 {
      font-family: "Anybody-SemiBold";
      font-weight: 600;
      font-size: 30px;
      margin:0px;
    }
  }
  .row {
    display: flex;
    justify-content: space-between;
    .col-2 {
      width: calc(50% - 10px);
    }
  }
  .services {
    margin-top: 30px;
    border-top: 1px solid #E3E3E3;
    .service {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #E3E3E3;
      border-radius: $radius;
      margin-bottom: 10px;
      padding: 5px 10px ;
      p {
        margin: 0px;
      }
      button {
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba($dark ,0.5);
        &:hover {
          color: $danger;
        }
      }
    }
    .add {
      display: flex;
      align-items: center;
      button {
        margin-left: 20px;
        margin-top: 5px;
        min-width: 140px;
        min-height: 42px;
      }
    }
  }
}