@import '../../../../../styles/variables.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 100;
  opacity: 0.6;
  cursor: pointer;
  @include transition;
  &:hover {
    opacity: 1;
  }
  p {
    position: absolute;
    font-size: 12px;
    min-width: 150px;
    width: 100%;
    text-align: center;
    transform: translate( 15px , -12px);
  }
  .bar {
    @include transition;
    width: calc(100% - 24px);
    height: 4px;
  }
  .start {
    @include transition;
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 10px;
  }
  .end {
    @include transition;
    width: 4px;
    min-width: 4px;
    height: 20px;
  }
}