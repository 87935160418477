@import '../../../../styles/variables.scss';

.container {
  width: 100%;
  font-size: 14px;
  padding: 20px;
  margin: 25px 0px;
  @include transition;
	background-color: $white;
	box-shadow: 0px 10px 28px rgb(224, 224, 224);
  border-radius: $radius;
  min-height: 55px;
  position: relative;
  cursor: grab;
  &.doctor {
    cursor: not-allowed;
  }
  &:not(:first-child) {
    margin-top: 20px;
  };
  &:hover {
    box-shadow: 0px 10px 18px rgb(197, 197, 197);
  }
  .circle {
    position: absolute;
    width: 30px;
    height: 30px;
    z-index: -2;
    border-radius: 15px;
    transform: translate(-35px , -35px);
  }
  .row {
    display: flex;
    p {
      margin: 5px;
      &.label {
        color: rgba($dark , 0.5);
        min-width: 105px;
      }
    }
  }
}