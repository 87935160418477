@import '../../../styles/variables.scss';

.container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100vh - 80px );
  z-index: 0;
  overflow-y: auto;
  @media only screen and (min-width: $md-screen) {
    top: 80px;
  }
  .row {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - 220px);
    @media only screen and (min-width: $md-screen) {
      min-height: calc(100vh - 140px);
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }
    .col {
      width: 100%;
      margin-bottom: 20px;
      @media only screen and (min-width: $md-screen) {
        width: calc(50% - 20px);
        margin-bottom: 20px;
      }
      &.illu {
        display: none;
        @media only screen and (min-width: $md-screen) {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            transform: translateY(35px)
          }
        }
      }
      h2 {
        font-family: "Anybody-Bold";
        font-size: 44px;
        margin-bottom: 20px;
      }
      .info-row {
        display: flex;
        flex-direction: column;
        width: 100%;
        &.status {
          border-top: 1px solid rgba($dark , 0.2);
          margin-top: 10px;
          padding-top: 20px;
        }
        @media only screen and (min-width: $md-screen) {
          flex-direction: row;
          justify-content: space-between;
        }
        .info-col {
          width: 100%;
          @media only screen and (min-width: $md-screen) {
            width: calc(50% - 10px);
          }
        }
      }
      .btn-container {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        &.start {
          justify-content: flex-start;
          .btn {
            margin-right: 20px;
          }
        }
      }
      .btn {
        p {
          margin: 0px;
          text-transform: uppercase;
          font-family: "Anybody-Bold";
          line-height: normal;
        }
        &.disabled {
          background-color: #CCCFD5;
          border: 1px solid #CCCFD5;
        }
        &.password {
          margin-top: 21px;
          margin-bottom: 23px;
          width: 100%;
          height: 45px;;
        }
      }
      p {
        line-height: 28px;
      }
    }
  }

  .links {
    a {
      margin: 20px;
      color: $dark-grey;
      &:hover {
        color: $primary;
      }
    }
  }
}